var exports = {};

/**
 * @typedef {{ [key: string]: any }} Extensions
 * @typedef {Error} Err
 * @property {string} message
 */

/**
 *
 * @param {Error} obj
 * @param {Extensions} props
 * @returns {Error & Extensions}
 */
function assign(obj, props) {
  for (const key in props) {
    Object.defineProperty(obj, key, {
      value: props[key],
      enumerable: true,
      configurable: true
    });
  }

  return obj;
}
/**
 *
 * @param {any} err - An Error
 * @param {string|Extensions} code - A string code or props to set on the error
 * @param {Extensions} [props] - Props to set on the error
 * @returns {Error & Extensions}
 */


function createError(err, code, props) {
  if (!err || typeof err === "string") {
    throw new TypeError("Please pass an Error to err-code");
  }

  if (!props) {
    props = {};
  }

  if (typeof code === "object") {
    props = code;
    code = "";
  }

  if (code) {
    props.code = code;
  }

  try {
    return assign(err, props);
  } catch (_) {
    props.message = err.message;
    props.stack = err.stack;

    const ErrClass = function () {};

    ErrClass.prototype = Object.create(Object.getPrototypeOf(err)); // @ts-ignore

    const output = assign(new ErrClass(), props);
    return output;
  }
}

exports = createError;
export default exports;